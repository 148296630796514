<template>
  <div class="dialog-container">
    <el-dialog
      title="场馆权限"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      center
      width="50%">
      <el-form ref="form" :model="form" label-width="88px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('role_name')" prop="roleName">
              <el-input clearable v-model="form.roleName" maxlength="20" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="权限字符" prop="roleKey">
              <el-input clearable v-model="form.roleKey" maxlength="20" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="显示顺序" prop="roleSort">
              <el-input clearable v-model="form.roleSort" maxlength="3" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="拥有权限" prop="venueId">
              <el-select v-model="form.venueId" multiple filterable style="width:100%" >
                <el-option :value="item.value" :label="item.label" v-for="item in venueList" :key="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import mixin from '@/mixins/dialogMixin'
import apis from '@/apis'
import AddDialogClass from './dataDialogClass'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      permissions: [],
      pidSet: [],
      venueList: [],
      permissionsValue: [],
    }
  },
  created() {
    this.loadPermissions()
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.pidSet = []
      if (this.$refs.tree) {
        this.$refs.tree.setCheckedKeys([])
      }
      this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post(apis.venueRoleMap_updateVenueRoleMapById, this.form).then((res) => {
            if (res.data.code === 0) {
              this.closeDialog()
            }
          })
        }
      })
      console.log(`我执行了点确定的业务需求`)
      // 调用关闭方法，不要再写一次
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.form.roleKey = data.roleKey
      this.form.roleName = data.roleName
      this.form.roleSort = data.roleSort
      this.form.id = data.roleId
      this.$http.post(apis.venueRoleMap_venueList).then((res) => {
        if (res.data.code === 0) {
          // 因为antd 树插件勾选父节点会导致所有子节点选中,所以过滤所有父节点
          this.venueList = res.data.rows
        }
      }).then((res) => {
        console.log(res)
      })
      this.$http.post(apis.venueRoleMap_selectVenueRoleMapById, { id: data.roleId }).then((res) => {
        if (res.data.code === 0) {
          // 因为antd 树插件勾选父节点会导致所有子节点选中,所以过滤所有父节点
          this.form = Object.assign({}, this.form, { venueId: res.data.rows })
        }
      }).then((res) => {
        console.log(res)
      })
    },
    loadPermissions() {
      this.$http.get(apis.dept_list).then((res) => {
        this.buildtree(res.data.rows, this.permissions, 0)
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          const child = {
            key: item.deptId.toString(),
            value: item.deptId.toString(),
            title: item.deptName,
            children: [],
          }
          this.buildtree(list, child.children, item.deptId)
          if (child.children.length === 0) { delete child.children }
          arr.push(child)
        }
      })
    },
    /**
     * @function 表单的树状选值触发的方法
     */
    handleCheckChange() {
      const deptIds = this.$refs.tree.getCheckedNodes(false, false)
      this.form.deptIds = deptIds.map(item => item.key)
    },
  },
}
</script>
