
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.status = ''
      this.roleSort = ''
      this.roleKey = ''
      this.roleName = ''
      this.menuIds = []
    } else if (type === 'rule') {
      this.roleName = [
        {
          required: true,
          message: '请输入角色名称',
          trigger: 'blur',
        },
      ]
      this.roleKey = [
        {
          required: true,
          message: '请输入权限字符',
          trigger: 'blur',
        },
      ]
      this.roleSort = [
        {
          required: true,
          message: '请输入显示顺序',
          trigger: 'blur',
        },
      ]
      this.status = [
        {
          required: true,
          message: '请选择状态',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
