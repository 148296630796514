
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.roleKey = ''
      this.venueId = []
      this.roleName = ''
      this.roleSort = ''
      this.id = ''
    } else if (type === 'rule') {
      this.roleName = [
        {
          required: true,
          message: '请输入角色名称',
          trigger: 'blur',
        },
      ]
      this.roleKey = [
        {
          required: true,
          message: '请输入权限字符',
          trigger: 'blur',
        },
      ]
      this.roleSort = [
        {
          required: true,
          message: '请输入显示顺序',
          trigger: 'blur',
        },
      ]
      this.venueId = [
        {
          required: true,
          message: '请选择权限',
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
